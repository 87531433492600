export const ClearIcon = () => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_11_654)">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M16 17.8854L23.5427 25.428C23.7942 25.6709 24.131 25.8053 24.4806 25.8023C24.8302 25.7992 25.1646 25.659 25.4118 25.4118C25.659 25.1646 25.7992 24.8302 25.8023 24.4806C25.8053 24.131 25.6709 23.7942 25.428 23.5427L17.8854 16L25.428 8.45737C25.6709 8.2059 25.8053 7.8691 25.8023 7.5195C25.7992 7.16991 25.659 6.83549 25.4118 6.58828C25.1646 6.34107 24.8302 6.20084 24.4806 6.1978C24.131 6.19477 23.7942 6.32916 23.5427 6.57204L16 14.1147L8.45736 6.57204C8.20476 6.33516 7.86991 6.20586 7.52367 6.21148C7.17743 6.2171 6.84695 6.35721 6.60217 6.60216C6.3574 6.84711 6.21752 7.17768 6.21214 7.52393C6.20677 7.87018 6.33631 8.20494 6.57336 8.45737L14.1147 16L6.57203 23.5427C6.44468 23.6657 6.34311 23.8128 6.27323 23.9755C6.20335 24.1382 6.16657 24.3131 6.16503 24.4902C6.16349 24.6672 6.19723 24.8428 6.26427 25.0066C6.33131 25.1705 6.43031 25.3194 6.5555 25.4446C6.68069 25.5698 6.82956 25.6688 6.99342 25.7358C7.15729 25.8028 7.33286 25.8366 7.5099 25.835C7.68694 25.8335 7.8619 25.7967 8.02457 25.7268C8.18724 25.657 8.33437 25.5554 8.45736 25.428L16 17.8854Z"
				fill="var(--title)"
			/>
		</g>
		<defs>
			<clipPath id="clip0_11_654">
				<rect width="32" height="32" fill="var(--title)" />
			</clipPath>
		</defs>
	</svg>
);
export const BarIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
	>
		<mask
			id="mask0_314_292"
			style={{ maskType: "alpha" }}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="25"
		>
			<rect y="0.552612" width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_314_292)">
			<path
				d="M3 18.5526V16.5526H21V18.5526H3ZM3 13.5526V11.5526H21V13.5526H3ZM3 8.55261V6.55261H21V8.55261H3Z"
				fill="#101010"
			/>
		</g>
	</svg>
);
export const Instructors = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="65"
		height="65"
		viewBox="0 0 65 65"
		fill="none"
	>
		<path
			d="M32.6667 18.5742L39.7014 22.2729L38.3577 14.4395L44.0525 8.88965L36.1843 7.74707L32.6667 0.605469L29.1492 7.74707L21.281 8.88965L26.9758 14.4395L25.6321 22.2729L32.6667 18.5742Z"
			fill="red"
		/>
		<path
			d="M56.4338 20.4966L52.9163 13.3687L49.3987 20.4966L41.5344 21.6392L47.2253 27.1875L45.8816 35.0225L52.9163 31.3237L59.9548 35.0225L58.6072 27.1875L64.6667 21.6392L56.4338 20.4966Z"
			fill="red"
		/>
		<path
			d="M6.72632 27.1875L5.37866 35.0225L12.4172 31.3237L19.4519 35.0225L18.1082 27.1875L23.7991 21.6392L15.9348 20.4966L12.4172 13.3687L8.89966 20.4966L0.666748 21.6392L6.72632 27.1875Z"
			fill="red"
		/>
		<path
			d="M42.0417 36.3555C42.0417 31.1865 37.8357 26.9805 32.6667 26.9805C27.4978 26.9805 23.2917 31.1865 23.2917 36.3555C23.2917 41.5244 27.4978 45.7305 32.6667 45.7305C37.8357 45.7305 42.0417 41.5244 42.0417 36.3555Z"
			fill="red"
		/>
		<path
			d="M19.5417 58.8555V64.6055H45.7917V58.8555C45.7917 51.6191 39.9031 45.7305 32.6667 45.7305C25.4304 45.7305 19.5417 51.6191 19.5417 58.8555Z"
			fill="red"
		/>
		<path
			d="M60.7917 62.6055C60.7917 57.4365 56.5857 53.2305 51.4167 53.2305C50.447 53.2305 49.53 53.4209 48.6497 53.6953C49.1804 55.332 49.5417 57.0444 49.5417 58.8555V64.6055H60.7917V62.6055Z"
			fill="red"
		/>
		<path
			d="M58.9167 45.7305C58.9167 41.5942 55.553 38.2305 51.4167 38.2305C47.2805 38.2305 43.9167 41.5942 43.9167 45.7305C43.9167 45.9692 43.9651 46.1948 43.9871 46.4277C45.8362 48.1133 47.3274 50.1704 48.2854 52.5234C49.2415 52.9663 50.2957 53.2305 51.4167 53.2305C55.553 53.2305 58.9167 49.8667 58.9167 45.7305Z"
			fill="red"
		/>
		<path
			d="M21.3464 46.4277C21.3684 46.1948 21.4167 45.9692 21.4167 45.7305C21.4167 41.5942 18.053 38.2305 13.9167 38.2305C9.78052 38.2305 6.41675 41.5942 6.41675 45.7305C6.41675 49.8667 9.78052 53.2305 13.9167 53.2305C15.0378 53.2305 16.092 52.9663 17.0481 52.5239C18.0061 50.1704 19.4973 48.1133 21.3464 46.4277Z"
			fill="red"
		/>
		<path
			d="M4.54175 62.6055V64.6055H15.7917V58.8555C15.7917 57.0444 16.1531 55.332 16.6838 53.6953C15.8035 53.4209 14.8865 53.2305 13.9167 53.2305C8.7478 53.2305 4.54175 57.4365 4.54175 62.6055Z"
			fill="red"
		/>
	</svg>
);
export const Confidence = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="46"
		height="65"
		viewBox="0 0 46 65"
		fill="none"
	>
		<path
			d="M45.9043 28.2497V32.0127C45.9043 39.6104 42.2433 46.4127 36.5799 50.6446C36.2507 50.8914 36.0561 51.278 36.0561 51.6895V62.1463C36.0561 63.2278 35.1797 64.1055 34.0969 64.1055H11.6681C10.5853 64.1055 9.70893 63.2278 9.70893 62.1463V51.5745C9.70893 51.1657 9.51693 50.7817 9.1917 50.5349C3.66678 46.3539 0.0958252 39.724 0.0958252 32.2595V28.2497C0.0958252 25.9522 1.95967 24.0884 4.25584 24.0884C6.55201 24.0884 8.41717 25.9496 8.41717 28.2497V32.3353C8.41717 40.3484 14.8864 46.8568 22.8891 46.9169C22.9256 46.9182 22.9635 46.9182 23.0014 46.9182C31.0537 46.9182 37.583 40.3875 37.583 32.3353V28.2497C37.583 25.9522 39.4468 24.0884 41.743 24.0884H41.7443C44.0405 24.0885 45.9043 25.9524 45.9043 28.2497Z"
			fill="red"
		/>
		<path
			d="M31.324 32.5235C31.324 37.1811 27.5467 40.9584 22.8891 40.9584C18.2301 40.9584 14.4541 37.1811 14.4541 32.5235C14.4541 27.8645 18.2301 24.0885 22.8891 24.0885C27.5467 24.0885 31.324 27.8644 31.324 32.5235Z"
			fill="red"
		/>
		<path
			d="M33.76 9.74969L30.6631 13.5113C30.3928 13.8392 30.2556 14.2571 30.2778 14.6803L30.5364 19.6867C30.6004 20.9249 29.3622 21.8131 28.2102 21.3546L23.6283 19.5339C23.3919 19.4399 23.1385 19.4007 22.8891 19.4177C22.7127 19.4294 22.539 19.4686 22.3718 19.5339L17.7899 21.3546C16.6379 21.8131 15.3997 20.9249 15.4637 19.6867L15.7223 14.6803C15.7445 14.2571 15.6074 13.8392 15.337 13.5113L12.2402 9.74969C11.4604 8.80144 11.9111 7.3647 13.0931 7.03295L17.9127 5.67849C18.3072 5.56747 18.6481 5.31931 18.8727 4.9771L21.5803 0.869329C21.8925 0.395205 22.3849 0.14051 22.8891 0.109163C23.4664 0.0712854 24.0607 0.324674 24.4198 0.869329L27.1274 4.9771C27.3521 5.31931 27.693 5.56747 28.0874 5.67849L32.9071 7.03295C34.0891 7.3647 34.5397 8.80144 33.76 9.74969Z"
			fill="red"
		/>
		<path
			d="M3.92852 8.18361C3.16352 7.41861 1.92296 7.41861 1.15783 8.18361C0.392702 8.94861 0.392702 10.1892 1.15783 10.9543L8.31959 18.1161C9.08472 18.8812 10.3252 18.8812 11.0903 18.1161C11.8554 17.3511 11.8554 16.1105 11.0903 15.3454L3.92852 8.18361Z"
			fill="red"
		/>
		<path
			d="M45.29 8.18361C44.525 7.41861 43.2844 7.41861 42.5193 8.18361L35.3575 15.3454C34.5924 16.1104 34.5924 17.3509 35.3575 18.1161C36.1227 18.8812 37.3631 18.8812 38.1282 18.1161L45.29 10.9543C46.0552 10.1892 46.0552 8.94861 45.29 8.18361Z"
			fill="red"
		/>
	</svg>
);
export const Opportunities = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="65"
		height="65"
		viewBox="0 0 65 65"
		fill="none"
	>
		<path
			d="M16.613 17.3889L11.0683 17.8173C9.52546 17.9365 8.15135 18.7452 7.2981 20.0358L0.903529 29.7072C0.256409 30.6859 0.153535 31.9108 0.628156 32.9838C1.1029 34.0568 2.07852 34.8045 3.23789 34.9842L8.31472 35.7704C9.50234 29.4679 12.3453 23.181 16.613 17.3889Z"
			fill="red"
		/>
		<path
			d="M28.6682 56.124L29.4546 61.2008C29.6342 62.3602 30.3819 63.3358 31.4548 63.8104C31.9012 64.0079 32.3737 64.1054 32.8438 64.1054C33.5042 64.1054 34.1599 63.9131 34.7315 63.5351L44.4032 57.1406C45.6938 56.2872 46.5024 54.913 46.6214 53.3704L47.0498 47.8257C41.2576 52.0935 34.9708 54.9365 28.6682 56.124Z"
			fill="red"
		/>
		<path
			d="M26.759 52.605C26.9353 52.605 27.1125 52.5905 27.2894 52.561C29.9299 52.1195 32.4748 51.3757 34.9026 50.4022L14.0365 29.5361C13.063 31.9637 12.3192 34.5087 11.8777 37.1493C11.7037 38.1902 12.0529 39.2506 12.799 39.9968L24.4419 51.6397C25.0614 52.2591 25.8975 52.605 26.759 52.605Z"
			fill="red"
		/>
		<path
			d="M59.23 28.4806C64.3361 18.6089 64.5253 8.17703 64.2584 3.28457C64.167 1.60821 62.8305 0.271717 61.154 0.180343C60.3574 0.136844 59.4133 0.105469 58.3492 0.105469C52.8785 0.105469 44.2222 0.934087 35.958 5.20868C29.3903 8.60578 20.7782 16.0858 15.6973 25.916C15.7573 25.9629 15.8159 26.0126 15.8711 26.0679L38.371 48.5677C38.4262 48.623 38.4758 48.6815 38.5227 48.7415C48.3529 43.6604 55.833 35.0483 59.23 28.4806ZM37.5452 13.6354C41.2003 9.98027 47.1479 9.97989 50.8032 13.6354C52.574 15.406 53.5491 17.7603 53.5491 20.2644C53.5491 22.7685 52.574 25.1229 50.8032 26.8935C48.9759 28.7209 46.5746 29.6347 44.1743 29.635C41.7732 29.6351 39.3731 28.7214 37.5452 26.8935C35.7745 25.1229 34.7992 22.7685 34.7992 20.2644C34.7992 17.7603 35.7745 15.406 37.5452 13.6354Z"
			fill="red"
		/>
		<path
			d="M40.1968 24.2418C42.3899 26.435 45.9587 26.4351 48.1518 24.2418C49.2142 23.1793 49.7993 21.7668 49.7993 20.2643C49.7993 18.7618 49.2142 17.3493 48.1518 16.287C47.0553 15.1903 45.6148 14.6421 44.1743 14.6421C42.7339 14.6421 41.2934 15.1903 40.1969 16.287C39.1346 17.3493 38.5493 18.7618 38.5493 20.2643C38.5493 21.7668 39.1344 23.1795 40.1968 24.2418Z"
			fill="red"
		/>
		<path
			d="M2.2464 52.8473C2.72628 52.8473 3.20615 52.6643 3.57215 52.298L9.69378 46.1764C10.426 45.4441 10.426 44.257 9.69378 43.5248C8.96165 42.7925 7.7744 42.7925 7.04215 43.5248L0.920525 49.6464C0.188275 50.3786 0.188275 51.5658 0.920525 52.298C1.28665 52.6641 1.76653 52.8473 2.2464 52.8473Z"
			fill="red"
		/>
		<path
			d="M15.3039 49.1349C14.5718 48.4026 13.3846 48.4026 12.6523 49.1349L0.882928 60.9043C0.150678 61.6365 0.150678 62.8236 0.882928 63.5559C1.24905 63.922 1.72893 64.105 2.2088 64.105C2.68868 64.105 3.16855 63.922 3.53455 63.5558L15.3038 51.7865C16.0362 51.0543 16.0362 49.8671 15.3039 49.1349Z"
			fill="red"
		/>
		<path
			d="M18.2622 54.745L12.1407 60.8666C11.4085 61.5989 11.4085 62.786 12.1407 63.5182C12.5069 63.8844 12.9867 64.0675 13.4665 64.0675C13.9462 64.0675 14.4262 63.8845 14.7922 63.5182L20.9139 57.3966C21.6461 56.6644 21.6461 55.4772 20.9139 54.745C20.1817 54.0127 18.9945 54.0127 18.2622 54.745Z"
			fill="red"
		/>
	</svg>
);
export const FaqClose = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="23"
		height="23"
		viewBox="0 0 23 23"
		fill="none"
	>
		<path
			d="M9.95837 22.3971V13.1471H0.708374V10.0637H9.95837V0.813721H13.0417V10.0637H22.2917V13.1471H13.0417V22.3971H9.95837Z"
			fill="#101010"
		/>
	</svg>
);
export const FaqOpen = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="23"
		height="4"
		viewBox="0 0 23 4"
		fill="none"
	>
		<path
			d="M13.0417 3.14705H15.0001H22.2917V0.0637207H15.0001H13.0417H0.708374V3.14705H13.0417Z"
			fill="#101010"
		/>
	</svg>
);
export const Phone = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="41"
		height="42"
		viewBox="0 0 41 42"
		fill="none"
	>
		<circle
			cx="20.4638"
			cy="21.0023"
			r="19.9641"
			stroke="white"
			stroke-width="0.999306"
		/>
		<mask
			id="mask0_301_2878"
			// style="mask-type:alpha"
			maskUnits="userSpaceOnUse"
			x="8"
			y="9"
			width="24"
			height="23"
		>
			<rect
				x="8.97412"
				y="9.50928"
				width="22.4347"
				height="22.4347"
				fill="white"
			/>
		</mask>
		<g mask="url(#mask0_301_2878)">
			<path
				d="M27.6231 29.14C25.6133 29.14 23.6543 28.6919 21.7461 27.7958C19.8373 26.9002 18.1469 25.7162 16.6749 24.2436C15.2023 22.7716 14.0183 21.0813 13.1228 19.1724C12.2266 17.2642 11.7786 15.3053 11.7786 13.2955C11.7786 13.015 11.872 12.7814 12.059 12.5944C12.246 12.4074 12.4796 12.314 12.7601 12.314H16.5459C16.764 12.314 16.9588 12.3841 17.1302 12.5243C17.3015 12.6645 17.4028 12.8437 17.434 13.0618L18.0416 16.3335C18.0727 16.5516 18.069 16.7501 18.0304 16.929C17.9911 17.1084 17.9014 17.2683 17.7611 17.4085L15.4943 19.6987C16.1487 20.8204 16.9706 21.8721 17.9603 22.8536C18.9492 23.8351 20.0358 24.6842 21.2198 25.4009L23.4166 23.2041C23.5568 23.0639 23.74 22.9586 23.9662 22.8882C24.1918 22.8184 24.4137 22.7991 24.6318 22.8302L27.8568 23.4846C28.0749 23.5313 28.254 23.6363 28.3943 23.7996C28.5345 23.9635 28.6046 24.1545 28.6046 24.3726V28.1585C28.6046 28.4389 28.5111 28.6726 28.3241 28.8595C28.1372 29.0465 27.9035 29.14 27.6231 29.14ZM14.6063 17.9226L16.1487 16.3803L15.7514 14.1835H13.6715C13.7494 14.8223 13.8584 15.4533 13.9987 16.0764C14.1389 16.6996 14.3414 17.315 14.6063 17.9226ZM26.735 27.2237V25.1672L24.5383 24.7231L22.9725 26.2889C23.5801 26.5538 24.1996 26.7641 24.8309 26.9199C25.4615 27.0757 26.0963 27.1769 26.735 27.2237Z"
				fill="white"
			/>
		</g>
	</svg>
);
export const Email = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="41"
		height="42"
		viewBox="0 0 41 42"
		fill="none"
	>
		<circle
			cx="20.4638"
			cy="20.9096"
			r="19.9641"
			stroke="white"
			stroke-width="0.999306"
		/>
		<mask
			id="mask0_301_2885"
			// style="mask-type:alpha"
			maskUnits="userSpaceOnUse"
			x="8"
			y="9"
			width="24"
			height="23"
		>
			<rect
				x="8.97412"
				y="9.41602"
				width="22.4347"
				height="22.4347"
				fill="white"
			/>
		</mask>
		<g mask="url(#mask0_301_2885)">
			<path
				d="M12.7118 28.1117C12.1977 28.1117 11.7577 27.9288 11.3919 27.563C11.0255 27.1966 10.8423 26.7563 10.8423 26.2422V15.0248C10.8423 14.5107 11.0255 14.0707 11.3919 13.7049C11.7577 13.3385 12.1977 13.1553 12.7118 13.1553H27.6683C28.1824 13.1553 28.6227 13.3385 28.9891 13.7049C29.3549 14.0707 29.5379 14.5107 29.5379 15.0248V26.2422C29.5379 26.7563 29.3549 27.1966 28.9891 27.563C28.6227 27.9288 28.1824 28.1117 27.6683 28.1117H12.7118ZM20.1901 21.5683L12.7118 16.8944V26.2422H27.6683V16.8944L20.1901 21.5683ZM20.1901 19.6987L27.6683 15.0248H12.7118L20.1901 19.6987ZM12.7118 16.8944V15.0248V26.2422V16.8944Z"
				fill="white"
			/>
		</g>
	</svg>
);
export const Telegram = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="41"
		height="42"
		viewBox="0 0 41 42"
		fill="none"
	>
		<circle
			cx="20.4638"
			cy="20.8173"
			r="19.9641"
			stroke="white"
			stroke-width="0.999306"
		/>
		<mask
			id="mask0_301_2892"
			// style="mask-type:alpha"
			maskUnits="userSpaceOnUse"
			x="8"
			y="9"
			width="24"
			height="23"
		>
			<rect
				x="8.97412"
				y="9.32373"
				width="22.4347"
				height="22.4347"
				fill="white"
			/>
		</mask>
		<g mask="url(#mask0_301_2892)">
			<path
				d="M21.0329 28.9544L18.3688 22.3642L11.7786 19.7001V18.3914L28.6046 12.1284L22.3416 28.9544H21.0329ZM21.6405 25.4957L25.4263 15.3067L15.2372 19.0925L19.8177 20.9153L21.6405 25.4957Z"
				fill="white"
			/>
		</g>
	</svg>
);
export const Facebook = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M23.1406 11.7812C23.1406 5.39117 17.9603 0.210938 11.5703 0.210938C5.18023 0.210938 0 5.39108 0 11.7812C0 17.5562 4.23111 22.343 9.76242 23.2109V15.1258H6.82466V11.7812H9.76242V9.23214C9.76242 6.33234 11.4898 4.73058 14.1326 4.73058C15.3986 4.73058 16.7227 4.95656 16.7227 4.95656V7.80393H15.2637C13.8263 7.80393 13.3781 8.69584 13.3781 9.61089V11.7812H16.5871L16.0741 15.1258H13.3781V23.2109C18.9095 22.343 23.1406 17.5563 23.1406 11.7812Z"
			fill="#232323"
		/>
	</svg>
);
export const LinkedinIn = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M21.097 0.210938C21.7748 0.210938 22.4248 0.480183 22.9041 0.959442C23.3833 1.4387 23.6526 2.08872 23.6526 2.76649V20.6554C23.6526 21.3332 23.3833 21.9832 22.9041 22.4624C22.4248 22.9417 21.7748 23.2109 21.097 23.2109H3.20814C2.53037 23.2109 1.88035 22.9417 1.40109 22.4624C0.921833 21.9832 0.652588 21.3332 0.652588 20.6554V2.76649C0.652588 2.08872 0.921833 1.4387 1.40109 0.959442C1.88035 0.480183 2.53037 0.210938 3.20814 0.210938H21.097ZM20.4581 20.0165V13.2443C20.4581 12.1395 20.0193 11.08 19.2381 10.2988C18.4569 9.51758 17.3974 9.07871 16.2926 9.07871C15.2065 9.07871 13.9415 9.74316 13.3281 10.7398V9.32149H9.76314V20.0165H13.3281V13.717C13.3281 12.7332 14.1204 11.9282 15.1043 11.9282C15.5787 11.9282 16.0337 12.1166 16.3692 12.4521C16.7047 12.7876 16.8931 13.2426 16.8931 13.717V20.0165H20.4581ZM5.61037 7.31538C6.1797 7.31538 6.72571 7.08922 7.12829 6.68664C7.53087 6.28406 7.75703 5.73805 7.75703 5.16872C7.75703 3.98038 6.7987 3.00927 5.61037 3.00927C5.03765 3.00927 4.48838 3.23678 4.08341 3.64176C3.67843 4.04673 3.45092 4.596 3.45092 5.16872C3.45092 6.35705 4.42203 7.31538 5.61037 7.31538ZM7.38648 20.0165V9.32149H3.84703V20.0165H7.38648Z"
			fill="#232323"
		/>
	</svg>
);
export const Instagram = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M6.83431 0.210938H16.4943C20.1743 0.210938 23.1643 3.20094 23.1643 6.88094V16.5409C23.1643 18.3099 22.4616 20.0065 21.2107 21.2573C19.9598 22.5082 18.2633 23.2109 16.4943 23.2109H6.83431C3.15431 23.2109 0.164307 20.2209 0.164307 16.5409V6.88094C0.164307 5.11194 0.867037 3.4154 2.1179 2.16454C3.36877 0.913668 5.06531 0.210938 6.83431 0.210938ZM6.60431 2.51094C5.50631 2.51094 4.45329 2.94711 3.67688 3.72352C2.90048 4.49992 2.46431 5.55294 2.46431 6.65094V16.7709C2.46431 19.0594 4.31581 20.9109 6.60431 20.9109H16.7243C17.8223 20.9109 18.8753 20.4748 19.6517 19.6984C20.4281 18.922 20.8643 17.8689 20.8643 16.7709V6.65094C20.8643 4.36244 19.0128 2.51094 16.7243 2.51094H6.60431ZM17.7018 4.23594C18.0831 4.23594 18.4487 4.38739 18.7183 4.65697C18.9879 4.92655 19.1393 5.29219 19.1393 5.67344C19.1393 6.05469 18.9879 6.42032 18.7183 6.6899C18.4487 6.95949 18.0831 7.11094 17.7018 7.11094C17.3206 7.11094 16.9549 6.95949 16.6853 6.6899C16.4158 6.42032 16.2643 6.05469 16.2643 5.67344C16.2643 5.29219 16.4158 4.92655 16.6853 4.65697C16.9549 4.38739 17.3206 4.23594 17.7018 4.23594ZM11.6643 5.96094C13.1893 5.96094 14.6518 6.56674 15.7302 7.64507C16.8085 8.72341 17.4143 10.1859 17.4143 11.7109C17.4143 13.2359 16.8085 14.6985 15.7302 15.7768C14.6518 16.8551 13.1893 17.4609 11.6643 17.4609C10.1393 17.4609 8.67678 16.8551 7.59844 15.7768C6.52011 14.6985 5.91431 13.2359 5.91431 11.7109C5.91431 10.1859 6.52011 8.72341 7.59844 7.64507C8.67678 6.56674 10.1393 5.96094 11.6643 5.96094ZM11.6643 8.26094C10.7493 8.26094 9.87179 8.62442 9.22479 9.27142C8.57779 9.91842 8.21431 10.7959 8.21431 11.7109C8.21431 12.6259 8.57779 13.5035 9.22479 14.1505C9.87179 14.7975 10.7493 15.1609 11.6643 15.1609C12.5793 15.1609 13.4568 14.7975 14.1038 14.1505C14.7508 13.5035 15.1143 12.6259 15.1143 11.7109C15.1143 10.7959 14.7508 9.91842 14.1038 9.27142C13.4568 8.62442 12.5793 8.26094 11.6643 8.26094Z"
			fill="#232323"
		/>
	</svg>
);
export const Youtube = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 34 24"
		fill="none"
	>
		<path
			d="M13.8191 16.6395L22.3456 11.7109L13.8191 6.78237V16.6395ZM32.8106 3.77594C33.0241 4.54808 33.172 5.58308 33.2706 6.89737C33.3856 8.21165 33.4348 9.34522 33.4348 10.3309L33.5334 11.7109C33.5334 15.3088 33.2706 17.9538 32.8106 19.6459C32.3998 21.1245 31.447 22.0774 29.9684 22.4881C29.1963 22.7017 27.7834 22.8495 25.6148 22.9481C23.4791 23.0631 21.5241 23.1124 19.717 23.1124L17.1048 23.2109C10.2213 23.2109 5.93341 22.9481 4.24127 22.4881C2.7627 22.0774 1.80984 21.1245 1.39913 19.6459C1.18556 18.8738 1.0377 17.8388 0.939127 16.5245C0.824127 15.2102 0.774841 14.0767 0.774841 13.0909L0.67627 11.7109C0.67627 8.11308 0.939127 5.46808 1.39913 3.77594C1.80984 2.29737 2.7627 1.34451 4.24127 0.933795C5.01341 0.720223 6.42627 0.572366 8.59484 0.473794C10.7306 0.358794 12.6856 0.309509 14.4927 0.309509L17.1048 0.210938C23.9884 0.210938 28.2763 0.473795 29.9684 0.933795C31.447 1.34451 32.3998 2.29737 32.8106 3.77594Z"
			fill="#232323"
		/>
	</svg>
);
export const Twitter = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 29 24"
		fill="none"
	>
		<path
			d="M28.3489 2.91682C27.3072 3.39035 26.1842 3.70153 25.0207 3.85035C26.2113 3.13329 27.1313 1.99682 27.5642 0.630349C26.4413 1.30682 25.1966 1.78035 23.8842 2.05094C22.8154 0.887408 21.3136 0.210938 19.6089 0.210938C16.4295 0.210938 13.8319 2.80858 13.8319 6.01506C13.8319 6.47506 13.886 6.92153 13.9807 7.34094C9.16423 7.09741 4.87541 4.78388 2.0207 1.27976C1.52012 2.13211 1.236 3.13329 1.236 4.18858C1.236 6.20447 2.2507 7.99035 3.82012 9.00506C2.85953 9.00506 1.96659 8.73447 1.18188 8.32858V8.36917C1.18188 11.1833 3.18423 13.5374 5.836 14.0651C4.98463 14.298 4.09083 14.3305 3.22482 14.1598C3.59229 15.3131 4.31196 16.3223 5.28267 17.0455C6.25338 17.7687 7.42631 18.1695 8.63659 18.1915C6.58503 19.8156 4.04199 20.6935 1.42541 20.6809C0.96541 20.6809 0.50541 20.6539 0.0454102 20.5998C2.616 22.2503 5.67365 23.2109 8.94776 23.2109C19.6089 23.2109 25.4672 14.3627 25.4672 6.69153C25.4672 6.43447 25.4672 6.19094 25.4536 5.93388C26.5901 5.12211 27.5642 4.09388 28.3489 2.91682Z"
			fill="#232323"
		/>
	</svg>
);
